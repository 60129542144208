import React, { useState, useEffect } from "react";
import { FaFile, FaLaptop } from "react-icons/fa";
import { LiaStarSolid } from "react-icons/lia";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Background from "../../../assets/background.png";
import axios from "axios";
import apiKey from "../../../config/apiKey";
import { useParams } from "react-router-dom"; // Import useParams hook
import { toast } from "react-toastify";
import { IoChevronDownOutline, IoChevronUp } from "react-icons/io5";
import { FaFacebook, FaWhatsapp, FaLinkedin, FaInstagram ,FaMapMarkerAlt, FaLink, FaClipboard} from 'react-icons/fa';
import { FaPaperPlane } from 'react-icons/fa'; 
const HomeTeacherPortfolio = () => {
  const student = JSON.parse(localStorage.getItem("edo-student"));
  const [isLoading, setIsLoading] = useState(false); // Add isLoading state
  const navigate = useNavigate();
  const [teacherDetails, setTeacherDetails] = useState(null);
  const [bySubjectsData, setBySubjectsData] = useState({});
  const [bySkillData, setBySkillData] = useState([]);
  const [byTimeSlotData, setByTimeSlotData] = useState([]);
  const [demoDuration, setDemoDuration] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [isAlwaysAvailable, setIsAlwaysAvailable] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false); // Modal state
  const { id } = useParams(); // Fetch id parameter from URL
  const user = {
    id: student ? student.student.id : "",
  };
  const [formData, setFormData] = useState({
    teacher_id: id,
    student_id: user.id,
    type_id: 1,
    timeslot_id: "",
    session_id: "",
    payment: "",
    topic: "",
  });

  const [loading, setLoading] = useState(true); // Add loading state
  useEffect(() => {
    setLoading(true); // Start loading before data fetch
    axios
      .get(`${apiKey}/api/edolutions/student/teacherdetail/${id}`, {
        headers: {
          Authorization: `Bearer ${student?.token}`,
        },
      })
      .then((response) => {
        setTeacherDetails(response.data.teacherDetails);
        setBySubjectsData(response.data.bySubjectsData);
        setBySkillData(response.data.skillsData);
        setByTimeSlotData(response.data.timeSlotData);
        setIsAlwaysAvailable(response.data.timeSlotData.length === 0);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [student?.token, id]);

  useEffect(() => {
    axios
      .get(`${apiKey}/api/edolutions/student/demoduration`, {
        headers: {
          Authorization: `Bearer ${student?.token}`,
        },
      })
      .then((response) => {
        setDemoDuration(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [student?.token]);

  const handleDurationChange = async (e) => {
    const { value } = e.target;
    const selectedDuration = demoDuration.find(
      (duration) => duration.id === parseInt(value)
    );
  
    let payment = "";
    if (selectedDuration && teacherDetails) {
      payment = selectedDuration.days * teacherDetails.online_charges;
    }
  
    setSelectedDuration(selectedDuration);
  
    // Check if the selected duration's ID is 5
    if (selectedDuration.id === 5) {
      try {
        const response = await axios.post(
          `${apiKey}/api/edolutions/student/validate-demo-request`,
          {
            student_id: user.id,
            teacher_id: id,
            session_id: selectedDuration.id, // Assuming session_id is duration id
          },
          {
            headers: {
              Authorization: `Bearer ${student?.token}`, // Authorization header
            },
          }
        );
  
        if (response.status === 200) {
          // Validation passed
          setErrorMessage(''); // Clear any previous error
          setIsValid(true);
          setFormData({
            ...formData,
            session_id: value,
            payment: payment,
          });
        }
      } catch (error) {
        // If validation fails but the error comes as 400, show the error message instead of logging it
        if (error.response && error.response.status === 400) {
          setErrorMessage(error.response.data.message); // Set the error message from the API response
          setIsValid(false);
        } else {
          // Handle other types of errors (optional)
          setErrorMessage("An unexpected error occurred.");
        }
      }
    } else {
      // Clear the error message if the duration ID is not 5
      setErrorMessage('');
      setFormData({
        ...formData,
        session_id: value,
        payment: payment,
      });
    }
  };
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [requestSubmitted, setRequestSubmitted] = useState(false);
  const [currentid, setCurrentId] = useState(null); // State to hold the ID
  const [errors, setErrors] = useState({});
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading to true on form submit
    try {
      const response = await axios.post(
        `${apiKey}/api/edolutions/student/teacherrequest`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${student?.token}`,
          },
        }
      );
      const requestId = response.data.request_id;
      // console.log("Created Teacher Request ID:", requestId);
      setCurrentId(requestId);
      // console.log(requestId);
      setRequestSubmitted(true);
      toast.success(response.data.message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: {
          backgroundColor: "#4FD1C5", // Customize background color
          color: "#fff", // Customize text color
        },
      });

      setFormData({
        teacher_id: id,
        student_id: user.id,
        type_id: 1,
        timeslot_id: "",
        session_id: "",
        payment: "",
        topic: "",
      });
      setShowModal(false);
    } catch (error) {
      if (error.response && error.response.data.errors) {
        // Handle validation errors
        setErrors(error.response.data.errors);
      } else {
        // Handle general error
        toast.error("Error submitting the form", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } finally {
      setIsLoading(false); // Reset loading state after the request completes
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.delete(
        `${apiKey}/api/edolutions/student/teacherrequestdelete/${currentid}`,
        {
          headers: {
            Authorization: `Bearer ${student?.token}`,
          },
        }
      );
      setRequestSubmitted(false);
      toast.success(response.data.message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: {
          backgroundColor: "#4FD1C5", // Customize background color
          color: "#fff", // Customize text color
        },
      });
    } catch (error) {
      toast.error("Error submitting the form", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const [expandedClasses, setExpandedClasses] = useState({});

  const toggleClass = (className) => {
    setExpandedClasses((prev) => ({
      ...prev,
      [className]: !prev[className],
    }));
  };

  const formatTime = (time) => {
    const [hour, minute] = time.split(":");
    const suffix = hour >= 12 ? "PM" : "AM";
    const formattedHour = hour % 12 || 12; // Convert 0 to 12 for 12 AM
    return `${formattedHour}:${minute} ${suffix}`;
  };

  // time slot work
  const [visibleCount, setVisibleCount] = useState(4);
  const [showAll, setShowAll] = useState(false);

  // Function to toggle showing more or less time slots
  const handleToggleView = () => {
    if (showAll) {
      setVisibleCount(4); // Reset to initial visible count
    } else {
      setVisibleCount(byTimeSlotData.length); // Show all time slots
    }
    setShowAll((prevState) => !prevState); // Toggle between showing more and less
  };

  const handleInstagramShare = () => {
    const profileUrl = `https://edolutions.com/Teacherprof/${id}`;
    navigator.clipboard.writeText(profileUrl); // Copy the URL to clipboard
    toast.success("Profile link copied!", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      style: {
        backgroundColor: "#4FD1C5", // Customize background color
        color: "#fff", // Customize text color
      },
    });
};

  const handleShare = (platform) => {
    const profileUrl = `https://edolutions.com/Teacherprof/${id}`; // Get the current page URL
    

    let shareUrl = '';
    switch (platform) {
        case 'facebook':
            shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(profileUrl)}`;
            break;
        case 'whatsapp':
            shareUrl = `https://wa.me/?text=${encodeURIComponent(profileUrl)}`;
            break;
        case 'linkedin':
            shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(profileUrl)}`;
            break;
        case 'instagram':
            shareUrl = `https://www.instagram.com/`; // Instagram doesn't have direct sharing via URL
            toast.info("Instagram sharing needs to be done manually.");
            return;
        default:
            return;
    }
    window.open(shareUrl, '_blank');
};
  return (
    <div className="m-3 flex flex-col break-words w-full mb-4 shadow-lg bg-white rounded-lg  border-0">
      <div className="flex items-center xl:p-5 sm:p-0 justify-center">
        {loading ? (
          <div>
            <div colSpan="9" className="py-5 px-1 text-center">
              <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-200 bg-opacity-5 backdrop-blur-sm z-50">
                <div className="w-12 h-12 border-4 border-t-4 border-gray-200 border-solid rounded-full animate-spin border-t-[#2DD0A5]"></div>
                <p className="mt-4 text-lg font-semibold text-[#2dd0a5]">
                  Edolution...
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full  xl:p-5 sm:p-0">
            <div className="p-3">
              {/* <Card card={card} /> */}
              <div
                className="flex gap-2 items-center cursor-pointer"
                onClick={() => navigate(-1)}
              >
                <BiArrowBack className="h-5 w-5" />
                <p className="text-[14px]">Back</p>
              </div>
              <div className="xl:mx-10 mx-0">
                <div
                  className="bg-cover p-4"
                  style={{ backgroundImage: `url(${Background})` }}
                >
                  <div className="flex flex-wrap items-center justify-between gap-7 my-7">
                    <div className="flex flex-col items-center justify-center text-center">
                      <div>
                        <img
                          src={
                            teacherDetails?.photo
                              ? `${apiKey}/userProfile/${teacherDetails.photo}`
                              : `${apiKey}/userProfile/87654321.png`
                          }
                          alt={
                            teacherDetails?.name || "Teacher profile picture"
                          }
                          className="rounded-full border w-24 h-24"
                        />
                      </div>
                      <div>
                        {teacherDetails && (
                          <p className="font-semibold capitalize text-xl">
                            {teacherDetails.user_name}
                          </p>
                        )}
                        {teacherDetails && (
                          <p className="font-normal text-base">
                            Rs.{teacherDetails.home_charges} per/hour
                          </p>
                        )}
                      </div>
                    </div>
                        <div className="flex flex-col gap-4">
                    <div>
                      {requestSubmitted ? (
                        <button
                          className="bg-[#59F5DE] rounded-lg text-white text-xl font-medium py-3 px-8"
                          onClick={handleDelete}
                        >
                          Cancel Request
                        </button>
                      ) : (
                        <button
                          className="bg-[#5963F5] rounded-lg text-white text-xl font-medium py-3 px-8"
                          onClick={() => setShowModal(true)}
                        >
                          Request to Apply
                        </button>
                      )}
                    </div>
                   <div className="ml-auto space-x-2">
  {/* Share Profile Button */}
  <button
    className="bg-[#5963F5] text-white px-4 py-2 rounded-lg flex items-center space-x-2"
    onClick={() => setIsShareModalOpen(true)} // Open modal
  >
    <span>Share Profile</span>
    <FaPaperPlane className="w-5 h-5" />
  </button>
</div>
                    
                        </div>
                  </div>
                </div>
              </div>
            </div>
            {isShareModalOpen && (
                <div className="fixed inset-0 bg-gray-900 bg-opacity-50 z-50 flex items-center justify-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <h2 className="text-xl font-semibold mb-4">Share this profile</h2>
                        
                        {/* Social Media Buttons Row */}
                        <div className="flex justify-between space-x-3">
                            {/* Facebook Share */}
                            <button
                                onClick={() => handleShare('facebook')}
                                className="bg-blue-600 text-white flex items-center justify-center w-1/4 py-2 px-2 rounded-lg"
                            >
                                <FaFacebook className=" " size={24} />
                                
                            </button>

                            {/* WhatsApp Share */}
                            <button
                                onClick={() => handleShare('whatsapp')}
                                className="bg-green-500 text-white flex items-center justify-center w-1/4 py-2 px-2 rounded-lg"
                            >
                                <FaWhatsapp className=" " size={24} />
                                
                            </button>

                            {/* LinkedIn Share */}
                            <button
                                onClick={() => handleShare('linkedin')}
                                className="bg-blue-700 text-white flex items-center justify-center w-1/4 py-2 px-2 rounded-lg"
                            >
                                <FaLinkedin className=" " size={24} />
                                
                            </button>

                            {/* Instagram Share (Copy to Clipboard) */}
                            <button
                                onClick={handleInstagramShare}
                                className="bg-gray-600 text-white flex items-center justify-center w-1/4 py-2 px-2 rounded-lg"
                            >
                                <FaLink  className=" " size={24} />
                                
                            </button>
                        </div>

                        {/* Close button */}
                        <button
                            onClick={() => setIsShareModalOpen(false)} // Close modal
                            className="mt-4 text-gray-500 w-full"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}

            <div className="grid grid-cols-1 sm:grid-cols-3 ">
              <div className="   md:px-4 md:py-4 col-span-1 sm:col-span-2 md:col-span-2 lg:col-span-2 xl:col-span-2 space-y-5">
                <div className="border rounded-3xl shadow-xl p-5">
                  <p className="text-2xl font-semibold mb-3">About</p>
                  {teacherDetails && (
                    <p className="text-sm font-light">{teacherDetails.bio}</p>
                  )}
                </div>

                <div className="border rounded-3xl shadow-xl p-5">
                  <p className="text-2xl font-semibold mb-3">Education</p>
                  <div>
                    {teacherDetails && (
                      <p className="text-sm font-semibold">
                        {teacherDetails.degree}
                      </p>
                    )}
                    {teacherDetails && (
                      <p className="text-sm font-light">
                        {teacherDetails.school}
                      </p>
                    )}
                    {teacherDetails && (
                      <p className="text-sm font-light mb-3">
                        Graduation Year: {teacherDetails.graduation_year}
                      </p>
                    )}
                  </div>
                  <p className="text-2xl font-semibold mb-3">Job</p>
                  <div>
                    {teacherDetails && (
                      <p className="text-sm font-semibold">
                        {teacherDetails.title}
                      </p>
                    )}
                    {teacherDetails && (
                      <p className="text-sm font-light">
                        {teacherDetails.company}
                      </p>
                    )}
                    {teacherDetails && (
                      <p className="text-sm font-light">
                        Start Date: {teacherDetails.start_month}
                        {","}
                        {teacherDetails.start_year}
                      </p>
                    )}
                    {teacherDetails && (
                      <p className="text-sm font-light mb-3">
                        End Date: {teacherDetails.end_month}
                        {","}
                        {teacherDetails.end_year}
                      </p>
                    )}
                    {teacherDetails && (
                      <p className="text-sm font-light mb-3">
                        Job Description: {teacherDetails.description}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="  col-span-1 sm:col-span-1 md:col-span-1 lg:col-span-1 xl:col-span-1 md:px-4 md:py-4 overflow-x-auto space-y-5 mt-5 md:mt-0">
                <div className="border rounded-3xl shadow-xl p-5">
                  <p className="text-2xl font-semibold mb-2">Time</p>
                  {byTimeSlotData && byTimeSlotData.length > 0 ? (
                    <div>
                      {byTimeSlotData.slice(0, visibleCount).map((timeSlot) => (
                        <div key={timeSlot.id}>
                          <p className="text-sm font-semibold capitalize">
                            {timeSlot.day_name}:
                          </p>
                          <p className="text-sm font-normal">
                            Start Time: {formatTime(timeSlot.start_time)} End
                            Time: {formatTime(timeSlot.end_time)}
                          </p>
                        </div>
                      ))}
                      {byTimeSlotData.length > 4 && (
                        <button
                          className="mt-3 text-blue-500 font-semibold"
                          onClick={handleToggleView}
                        >
                          {showAll ? "Show Less" : "View More"}
                        </button>
                      )}
                    </div>
                  ) : (
                    <p>No time slots available</p>
                  )}
                </div>
                <div className="border rounded-3xl shadow-xl p-5">
                  <p className="text-2xl font-semibold mb-2">Skills</p>
                  {bySkillData.map((skill, index) => (
                    <p key={index} className="text-sm font-semibold">{skill.skill_name}</p>
                  ))}
                </div>
                <div className="border rounded-3xl shadow-xl p-5">
                  <p className="text-2xl font-semibold mb-3">
                    Profile Overview
                  </p>

                  <p className="flex text-yellow-400 items-center">
                    <LiaStarSolid />
                    <LiaStarSolid />
                    <LiaStarSolid />
                    <span className="text-black">5.0</span>
                  </p>
                  <div>
                    <div className="flex items-center text-base font-semibold space-x-1 mb-2">
                      <p className="text-lg">
                        <FaLaptop />
                      </p>

                      <p>Home Tuition Location</p>
                    </div>
                    <div>
                      {teacherDetails && (
                        <p className="text-sm font-semibold">
                          {teacherDetails.location}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="border rounded-3xl shadow-xl p-5">
                  <p className="text-2xl font-semibold mb-3">Subjects</p>
                  <div>
                    <div className="flex items-center text-base font-semibold space-x-1 mb-2">
                      <p className="text-lg">
                        <FaFile />
                      </p>
                      <p>Main Classes & Subjects</p>
                    </div>
                    <div>
                      {Object.keys(bySubjectsData).map((className) => (
                        <div key={className}>
                          <div
                            className="cursor-pointer flex items-center"
                            onClick={() => toggleClass(className)}
                          >
                            <p className="text-base font-semibold">
                              {className}
                            </p>
                            <span className="ml-2">
                              {expandedClasses[className] ? (
                                <IoChevronUp />
                              ) : (
                                <IoChevronDownOutline />
                              )}
                            </span>
                          </div>
                          {expandedClasses[className] && (
                            <ul>
                              {bySubjectsData[className].map((subject) => (
                                <li className="text-sm" key={subject}>
                                  {subject}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* modal */}
      <div>
        {showModal ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative mx-auto w-[80%] md:w-1/3">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none overflow-y-auto shiftmodel">
                  <div className="items-start justify-center p-5 border-solid border-blueGray-200 rounded-t">
                    <button
                      className="ml-auto float-right text-2xl"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="text-[#000]"> × </span>
                    </button>
                  </div>
                  <div className="text-center">
                    <h1 className="text-[24px] font-semibold text-[#000]">
                      Select Slot
                    </h1>
                  </div>
                  <div className="text-center">
                    <form onSubmit={handleSubmit}>
                    <div className="mt-8">
                        <select
                          name="session_id"
                          className="text-[#B5B7C0] text-md dropdown-select border rounded-lg outline-none bg-[#F9FBFF] w-2/4 px-1 py-2 cursor-pointer my-2"
                          onChange={handleDurationChange}
                          value={formData.session_id}
                        >
                          <option value={JSON.stringify({})}>
                            Select Duration
                          </option>
                          {demoDuration.map((demoDuration, index) => (
                            <option key={index} value={demoDuration.id}>
                              {demoDuration.days} {demoDuration.duration}
                            </option>
                          ))}
                        </select>
                        {/* Render error message if it exists */}
                        {errorMessage && (
                          <div className="error-message text-red-500 text-sm ">
                            {errorMessage}
                          </div>
                        )}
                      </div>
                      <div>
                        {!isAlwaysAvailable ? (
                          <select
                            name="timeslot_id"
                            className="text-[#B5B7C0] text-md dropdown-select border rounded-lg outline-none bg-[#F9FBFF] w-2/4 px-1 py-2 cursor-pointer my-2"
                            onChange={handleChange}
                            value={formData.timeslot_id}
                          >
                            <option value="">Select Time Slot</option>
                            {byTimeSlotData.map((timeSlot, index) => (
                              <option
                                className="capitalize"
                                key={index}
                                value={timeSlot.id}
                              >
                                {timeSlot.day_name}:{" "}
                                {formatTime(timeSlot.start_time)} -{" "}
                                {formatTime(timeSlot.end_time)}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <input
                            type="datetime-local"
                            name="timeslot_id"
                            className="text-[#B5B7C0] text-md border rounded-lg outline-none bg-[#F9FBFF] w-2/4 px-2 py-2 cursor-pointer my-2"
                            onChange={handleChange}
                            value={formData.timeslot_id || ""}
                          />
                        )}
                        {errors.timeslot_id && !isAlwaysAvailable && (
                          <div className="error-message text-red-500 text-sm ">
                            {"The timeslot field is required."}
                          </div>
                        )}
                        {errors.timeslot_id && isAlwaysAvailable && (
                          <div className="error-message text-red-500 text-sm ">
                            {"The required timeslot field is required."}
                          </div>
                        )}
                      </div>

                      <div>
                        <input
                          type="text"
                          name="payment"
                          placeholder="Payment"
                          className="text-[#B5B7C0] text-md border rounded-lg outline-none bg-[#F9FBFF] w-2/4 px-2 py-2 cursor-pointer my-2"
                          onChange={handleChange}
                          value={formData.payment}
                          disabled={!(formData.payment === "")}
                        />
                        {errors.payment && (
                          <div className="error-message text-red-500  text-sm ">
                            {"The payment field is required."}
                          </div>
                        )}
                      </div>
                      <div>
                        <input
                          type="text"
                          name="topic"
                          placeholder="Enter the Topic"
                          className="text-[#B5B7C0] text-md border rounded-lg outline-none bg-[#F9FBFF] w-2/4 px-2 py-2 cursor-pointer my-2"
                          onChange={handleChange}
                          value={formData.topic}
                        />
                        {errors.topic && (
                          <div className="error-message text-red-500  text-sm ">
                            {"The topic field is required."}
                          </div>
                        )}
                      </div>
                      <div className="text-center mb-7 mt-4">
                        <button
                          type="submit"
                          className={`bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-10 rounded-lg focus:outline-none focus:shadow-outline ${
                            isLoading ? "opacity-50 cursor-not-allowed" : ""
                          }`}
                          disabled={isLoading} // Disable the button when loading
                        >
                          {isLoading ? (
                            <svg
                              className="animate-spin h-5 w-5 text-white mx-auto"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default HomeTeacherPortfolio;
